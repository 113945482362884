import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { useEffect, useState } from 'react'; 
import { getPrincipalMessage } from '../Service/Api';
import { Link } from 'react-router-dom'
const PrincipalMessage = () => {
    const [data, setData] = useState([]);
    console.log(data);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                console.log(principalData);
            } catch (error) {
                console.log("Data Error", error);
            }
        };
        fetchData();
    }, []);
    const emptyData = [
        { id: 1, category: "Designation", message: "Stay Tuned for More Updates", name: "Name", images: "/Images/demo.jpg" }
    ];
  return (
     <>
       <Header/>
        <div className="innerslide"></div> 
       
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li> 
                            <li>About Us</li>
                            <li>Principal's Message </li>
                        </ul>
                        <h1>  Principal's Message </h1>
                    </div>
                </div>
                <div className="row">
                    
                {data.length > 0 ? data.map((item) => (
   
                    <div className="col-lg-12">
                       <div className="msgbox">
                           <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="St. Mary’s Convent Sr. Sec. School, Ujjain" className="img-fluid"/>
                       </div>
                       <p>{item.message}</p>
                       <p className="name"> {item.name}<span> {item.category}  </span></p>
                   </div>
               )) : emptyData.map((item) => (
                  
                    <div className="col-lg-12">
                       <div className="msgbox">
                           <img src={item.images} alt="St. Mary’s Convent Sr. Sec. School, Ujjain" className="img-fluid"/>
                       </div>
                         <p>{item.message}</p>
                       <p className="name"> {item.name}<span> {item.category}  </span></p>
                   </div>
               ))}
               

                    {/*   <div className="col-lg-12">
                        <div className="msgbox">
                            <img src='/Images/principal.jpg' alt="St. Mary’s Convent Sr. Sec. School, Ujjain" className="img-fluid"/>
                        </div>
                        <p> The magnitude of human knowledge, globalization and the accelerating rate of change due to technology necessitate a shift in our system of education. Adjusting our mindset to the rapidity of changes and its impact on the fragile minds are a matter of concern. </p>
                        <p> A Question arises, ‘how should we, as Educators, guide the impressionable minds under our care and ensure that the child thrives in the environment of changes, maintaining the credibility through values and ethos that we have nurtured over the years?’ The basic tenets of character, ethics, values and morality should be kept intact. We need to steer the wheel of knowledge to thinking from plateaus of knowledge to continuous cycles of learning. </p>
                        <p> Martin Luther king quotes: “The function of education is to teach one to think intensively and to think critically”. Intelligence plus character that is the goal of true education. </p>
                        <p> As educators we need to follow the footprints of a great teacher of India, Dr.S.Radhakrishnan, who has stood the test of time by maintaining high standards of teaching – learning, strategies both as a learner and a teacher. </p>
                        <p>May God Bless our endeavour.</p>
                        <p className="name"> SR. S. ROBISES (FSMA)<span> Principal  </span></p>
                    </div> */}
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default PrincipalMessage