import React, { useEffect, useState } from "react";
import { getGallery } from "../Service/Api";

function Gallery() {
 const [data, setData] = useState([]);
 const [loading, setLoading] = useState(true); 
   useEffect(() => {
   const fetchData = async () => {
        try{
          const galleryData = await getGallery();
          setData(galleryData);
        }catch (error) {
          console.log("Error fetching gallery data:",error)
        }finally{
          setLoading(false)
        }
     
     };
   fetchData();
   }, []);

   const empty = [
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" },   
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" },   
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" },
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" },   
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" },
    { title: "St. Mary’s Convent Senior Secondary School Ujjain", desc: "St. Mary’s Convent Senior Secondary School Ujjain is run by the Franciscan Sisters of St. Mary of the Angels (F.S.M.A) ", img: "Images/demo.jpg" } 
    
    
  ];

  return (
    <>
        <div className="">
            <div className="title center">
                    <div>
                        <p>School in the images</p>
                        <h3>Gallery section</h3>
                    </div> 
                </div>
        <div className="container gallery-main animateMe" data-animation="fadeInUpBig">
          <div className="row"> 
                 {data.length > 0 ? (data.map((item) => (
                  <div className="col-lg-4 col-sm-6" key={item.id}>
                    <div className="gallery-blk">
                      <img
                        src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}
                        className="img-fluid lg-mid"
                      />
                      <div className="overlay">
                        <h3>{item.title}</h3>
                        <p>{item.desc}</p>
                      </div>
                    </div> 
                  </div> 
                 ))) : (empty.map((item,index) => (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <div className="gallery-blk">
                      <img
                        src={item.img}
                        className="img-fluid lg-mid"
                      />
                      <div className="overlay">
                        <h3>{item.title}</h3>
                        <p>{item.desc}</p>
                      </div>
                    </div> 
                </div> 

                 )))
                
                
                }
          </div>
             
            {/* <div className="row"> 
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery1.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery2.jpg"
                  className="img-fluid lg-small"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img src="/Images/gallery4.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery5.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery6.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery7.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery7.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="/Images/gallery7.jpg"
                  className="img-fluid lg-mid"
                />
                <div className="overlay">
                  <h3>Republic Day Celebration</h3>
                  <p>Description of the image</p>
                </div>
              </div>
            </div> 
  </div> */}
        </div>
        <div className="gallery-btn-block">
        <div className='button abt-button view-gal'><a href="/PhotoGallery"><img src="/Images/View-Gallery.png" /></a></div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
