import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import AllBirthdays from'../Pages/AllBirthdays'
import {getBirthdays} from'../Service/Api' 

const Birthday = () => {
     const [data,setData] = useState([]);
     const [loading, setLoading] = useState(true); 
        
       useEffect(() => {
            const fetchData = async () => {
                try{
                   const birthdayData = await getBirthdays();
                   setData(birthdayData);
                } catch (error) {
                   console.log("error Fetching data ",error)
                } finally{
                    setLoading(false)
                  }
            }
            fetchData();
       },[]);
    const emppty =[]
    var settings1 = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:2,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [ 
            {
              breakpoint: 767,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            }
          ]
      };
      const emptyData = [
        { id: 1,},
        { id: 2,},
        { id: 3,}
    ];
    return (
        <>
            <div className='col-md-12 col-lg-6'>
                <div className='birthday-block'>
                <div className="upcoming">
                    <img src="/Images/candle.gif" />
                    <h4>TODAY STAR</h4>
                </div>
                <Slider className='owl-theme birthday-theme' loop margin={10} {...settings1}>
                   {data && data.length > 0 ? (data.map((item,index) => (
                    <div className="item" key={index}>
                        <div className="birthday-content"> 
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} />
                            <p>{item.name}</p>
                            <p class="primary">{item.class}</p>
                        </div>
                    </div> 
                 ))) : (emptyData.map((item) => (
                    <div className="item" key={item.id}>
                        <div className="birthday-content"> 
                            <img src="/Images/birthday1.png" />
                            <p>Student Name</p>
                            <p class="primary">Class</p>
                        </div>
                    </div> 
                 )))}
                     
                    

                     {/* <div className="item">
                        <div className="birthday-content"> 
                            <img src="/Images/birthday4.png" />
                            <p>Bhagyashree Vishwakarma</p>
                            <p class="primary">VI - B</p>
                        </div>
                    </div> */}

                </Slider>
                </div>
            </div>

             <AllBirthdays/>

        </>
    )
}
export default Birthday