import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { postTransferCertificate } from '../Service/Api';
import { Link } from 'react-router-dom';

const Tc = () => {
   
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }
    
    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://d280nq1n4mqyso.cloudfront.net/api/image/${response.data}`)
        .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
        .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
        })
        .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };


  return (
    <>
      <Header />
      <div className="innerslide"></div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="breadcrumbli">
                <li><Link to="/">Home</Link></li>
                <li>Transfer Certificate</li>
              </ul>
              <h1>Transfer Certificate</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tcblock">
                <div className="tc">
                  <form onSubmit={handleSubmit}>
                    <div className="formfil">
                    <div className="form-group">
                    <label htmlFor="admissionNo"><p>Admission No.</p></label>
                    <input
                      type="text"
                      className="form-control"
                      id="admissionNo"
                      name="admissionNo"
                      autocomplete="off"
                      value={admission}  // Bind to admission state
                      onChange={handleAdmissionNoChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="studentName"><p>Student Full Name</p></label>
                    <input
                      type="text"
                      autocomplete="off"
                      className="form-control"
                      id="studentName"
                      name="studentName"
                      value={name}  // Bind to name state
                      onChange={handleFullNameChange}
                    />
                  </div>
                  
                    </div>
                    <div className="clr30"></div>
                    <button
                      type="submit"
                      className="viewdetailsbtn"
                    >
                      Submit
                    </button>
                  </form>
                </div>
                <iframe src='/pdf/TC-Sample.pdf#toolbar=0' width="100%" height="500"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tc;
