import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const GeneralInformation = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div>  
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>General Information  </li>
                        </ul>
                        <h1>  General Information </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 faclitibox">
                        <h5> School Hours</h5>
                    <div className=" schoolhours">
                          <p> K.G. I : 12.30 p.m. to 4.30 p.m. </p>
                          <p> K.G. II : 8.00 a.m. to 12.00 p.m. </p>
                          <p> Class I to V : 7.45 a.m. to 1.40 p.m. </p>
                          <p> Class VI to X : 7.45 a.m. to 2.20 p.m. </p>
                          <p> Recess : 10.40 a.m. to 11.00 a.m. </p>
                    </div>
                    <p>The management reserves the right to change the time as per the need.
                    </p>
                    <h5>Attendance And Leave Of Absence</h5>
                    <ul className=" mainlist">
                    <li> Students are awarded with merit certificate in case of 100% attendance. </li>
                    <li> Minimum attendance expected of students for pass is 90%. </li>
                    <li> Students will be sanctioned leave of absence only for very urgent reasons and on a written request from the parents or guardians. </li>
                    <li> The sanction for leave is to be obtained from the principal well in advance. </li>
                    <li> If a student is absent on account of illness, it should be notified immediately to the Principal. A medical certificate from a registered doctor must be submitted in case of prolonged sickness of more than a week. </li>
                    <li> If student is absent more than ten days without information, the name of such student shall be struck off from the roll. </li>
                    <li> If the student is absent on the re-opening day without prior permission, his/her name shall be struck off from the roll. </li>
                    </ul>
                    <h5>Holidays</h5>
                    
                    <p>Holidays of the Department of Education, Government of M.P. are respected with slight modifications as per the need of the school.</p>
                    
                    <h5>Rules Of Discipline</h5>
                    <ul className=" mainlist">
                    <li> Students must be in the school premises ten minutes before the school session. </li>
                    <li> All are expected to speak in English in the school even in their private conversations to have proficiency in the language. </li>
                    <li> No student is allowed to leave the school premises with or without parents/guardians or teachers or any other person without permission from the Principal. </li>
                    <li> Parents and guardians may not visit their wards or interview the teachers in the class room nor visit the teachers in their homes regarding school matters. Complaints if any may directly be addressed to the Principal/Headmistress. </li>
                    <li> A notice of three days must be given for any certificate or document required from the school office. </li>
                    <li> Any damage done by a pupil or a group of pupils to school property will be a punishable offence and must be paid for as per decision of the Principal. Habitual offenders may be rusticated or dismissed from the school. </li>
                    <li> No collection of funds for any purpose whatsoever or fine is to be charged in the name of the school without the explicit previous permission of the Principal. </li>
                    <li> Writing of any kind on the walls or furniture will be severely punished and the decision of the Principal will be final in this matter. </li>
                    <li> No mobile phones, cameras, books or literature other than text books or the reference texts are permitted within the school. </li>
                    <li>  Wearing expensive and gaudy jewellery and other fashions are prohibited in the school. All students must be marked by neatness and simplicity in their dress and behavior. All defaulters in this matter are liable to be expelled from the school. </li>
                    <li>  Running, playing, shouting and whistling inside the school building is never allow. </li>
                    <li>  Children should be supplied with lighter school bags and books should be arranged strictly according to the time table. </li>
                    <li>  The name, standard and section of the student should be marked on his/her belongings. </li>
                    <li>  Parents may not engage the teachers of the institution for private tuitions without the explicit permission of the Principal. </li>
                    <li>  Presents to the members of the staff or other demonstrations in their honour require prior sanction of the Principal. </li>
                    <li>  All should be particularly careful to throw paper, plastic bags etc. into the bins specially provided for this purpose. </li>
                    <li>  The Principal is fully entitled to punish the students herself or get them punished by any of the members of her staff materially or monetarily. All rights in this regard are reserved with the Principal. </li>
                    </ul>
                    
                    <h5>Requests And Recommendations To Parents</h5>
                    <ul className=" mainlist">
                    <li> Parents and guardians should actively co-operate with the staff by following their ward’s progress from the beginning of the scholastic year. </li>
                    <li> Parents and guardians are requested to study the rules and information given in the school and become fully conversant with the school policies and also have all the rules observed by their wards. </li>
                    <li> Parents and guardians are advised not to give their wards any valuable articles like expensive watches, jewellery, fountain pens etc. the school will not be responsible for goods, money , fees, books or any other belongings lost by their wards. </li>
                    <li> Parents and guardians will bear the responsibility for the good conduct, home studies, regular attendance and fees of their wards. The Principal reserves the right to or not to retain such pupils who act otherwise than conforming to the school ideals in and outside the school. Insubordination, contempt of authority, immorality, harmful conduct, irregular attendance, lack of punctuality, unsatisfactory progress in studies, disloyalty, dishonesty, specially using unfair means in examination, etc. are always sufficient reasons for removing a student from the school rolls. </li>
                    <li> Students who have to wait after school for their conveyance may do so in the school campus and should not be loitering around the shops outside the school. It may kindly be noted by the parents and guardians that stern action will be taken against those who are seen lingering on there. </li>
                    <li> To have proficiency in English Language it is compulsory for all the students to converse in English only, as long as they are in school premises. Strict action will be taken against those who fail to abide by the above norm. We request the co-operation of the parents and guardians in this matter. </li>
                    <li>  All the decisions arrived at by the authorities regarding a student will not be challengeable in any court or commission of law or department. </li>
                    <li> The school authorities have all the rights reserved to alter, revise or revoke any rule or rules and working pattern of St.Mary’s Convent Senior Secondary School, Ujjain, as per the need of the times. </li>
                    </ul>
                    
                    <p>“The Essence of education lies in drawing out the very best that is in you.”</p>
                    </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default GeneralInformation