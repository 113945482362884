import {getEvents} from'../Service/Api' 
import Slider from "react-slick";
import { useEffect, useState } from 'react'; 
const Events = () => {
const [data,setData] = useState([]);
const [loading,setLoading] = useState(true);
const [error,setError] = useState(null);

 useEffect(() => {
     const fetchData = async () =>{
          try{
            const result = await getEvents();
            setData(result);

          } catch {
            setError(error)
          }finally{
            setLoading(false)
          }

     }
fetchData();
 },[]);

 const empty = [
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00",images:"/Images/events.jpg"},
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00",images:"/Images/events.jpg"},
     { title:"Stay tunned for more Updates",date:"DD.MM.YY",venue:"school Campus",time:"00:00",images:"/Images/events.jpg"},
 ]
    var settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll:1,
                }
              },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
   

    return (
        <Slider className='owl-theme' loop margin={10} {...settings}>
         
        {data && data.length > 0 ?  (data.map((item,index) => (
        <div className="item" key={index}>
            <div className='evt-blk'>
                <div className="evt-img">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} className="img-fluid" />
                    
                    <div className="evt-title"><h3>{item.title}</h3></div> 
                </div>
                <div className='evt-content'> 
                    <p><img src="/Images/calender.png" />{item.date}<span><img src="/Images/clock.png"></img>{item.time}</span></p>
                    <p><img src="/Images/location.png" />{item.venue}</p>
                </div>
            </div>
    </div> 
     ))) :  (empty.map((item,index) => (
        <div className="item" key={item.index}>
            <div className='evt-blk'>
                <div className="evt-img">
                    <img src={item.images} className="img-fluid" />
                    
                    <div className="evt-title"><h3>{item.title}</h3></div> 
                </div>
                <div className='evt-content'> 
                    <p><img src="/Images/calender.png" />{item.date}<span><img src="/Images/clock.png"></img>{item.time}</span></p>
                    <p><img src="/Images/location.png" />{item.venue}</p>
                </div>
            </div>
    </div> 
     )))}


          { /*  <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="/Images/event1.png" />
                        
                        <div className="evt-title"><h3>Gautam Buddha Jayanti</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="/Images/calender.png" />23 May, 2024 <span><img src="/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="/Images/location.png" />School Campus </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="/Images/event2.png" />
                        
                        <div className="evt-title"><h3>International workers day</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="/Images/calender.png" />12 May, 2024 <span><img src="/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="/Images/location.png" />School Campus </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src="/Images/event3.png" />
                        
                        <div className="evt-title"><h3>ravindra nath tagore jayanti</h3></div> 
                    </div>
                    <div className='evt-content'> 
                        <p><img src="/Images/calender.png" />08 May, 2024 <span><img src="/Images/clock.png"></img>08:00AM</span></p>
                        <p><img src="/Images/location.png" />School Campus </p>
                    </div>
                </div>
    </div> */ }
        
            
        </Slider>
    );
};

export default Events;
