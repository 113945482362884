import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const AimsObjectives = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div>  
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>Aims and Objectives  </li>
                        </ul>
                        <h1>  Aims and Objectives </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p> To educate the students and to make them well qualified, reliable and good citizen ready to serve their country.Stress is laid on good conduct, character formation, commitment to justice, liberation of the poor and oppressed. </p>
                        <p> To give the students an all round education – moral, academic, social and physical – based on high ideals and principles with a deep faith in God. </p>
                        <p> To inculcate the spirit of love and selfless service of God and the country.To take a stand in favour of Human Rights and Empowerment of Women. </p>
                        <p> To generate co-operation, team spirit and solidarity rather than competition and individualism. </p>
                        <p> To inculcate eco-consciousness, national integrity and peaceful co-existence among the students and in the society at large. </p>
                   </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default AimsObjectives