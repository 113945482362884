import React, { useState } from 'react';

const Tab = ({ onClick, isActive, year, images }) => {
    return (
        <div onClick={onClick} className={`tabs-block ${isActive ? 'active' : ''}`}>
            <img src={images.static} />
            <img src={images.animated} className='faci-gif' />
        </div>
    );
};

const Facilities = () => {
    const [activeTab1, setActiveTab1] = useState('tab11'); 

    const tabs = [
        { id: 'tab11', year: '2023-2024', images: { static: 'Images/book.png', animated: 'Images/book.gif' }, content: 'Well Managed Library', description: 'School library is renovated according to the need. It is replenished every year with additional supply of books on different subjects and different themes. Library has a spacious reading room where students can look up reference books on various topics and read newspapers and periodicals. Children in and above class six are permitted to take books home according to the rules and regulations.' },
        { id: 'tab22', year: '2022-2023', images: { static: 'Images/lab.png', animated: 'Images/lab.gif' }, content: 'Advanced Laboratories', description: 'The school has advanced laboratories where students can perform experiments and learn scientific concepts practically. The labs are well-equipped with modern instruments and safety measures are strictly followed.' },
        { id: 'tab33', year: '2021-2022', images: { static: 'Images/note.png', animated: 'Images/note.gif' }, content: 'Modern Classrooms', description: 'Classrooms are equipped with modern amenities such as smart boards, projectors, and internet access to facilitate interactive learning. The environment is designed to be conducive to education.' },
       
    ];
    const tabs1 = [
        
        { id: 'tab44', year: '2020-2021', images: { static: 'Images/sport.png', animated: 'Images/sport.gif' }, content: 'Sports Facilities', description: 'A wide range of sports facilities including a gymnasium, playground, and courts for various games are available to encourage physical fitness and sportsmanship among students.' },
        { id: 'tab55', year: '2019-2020', images: { static: 'Images/stage.png', animated: 'Images/stage.gif' }, content: 'Cultural Activities', description: 'Various cultural activities such as music, dance, and drama are encouraged. Students can participate in these activities to explore and develop their talents.' },
        { id: 'tab66', year: '2018-2019', images: { static: 'Images/teach.png', animated: 'Images/teach.gif' }, content: 'Teacher Training', description: 'Regular training for teachers is conducted to keep them updated with the latest teaching methodologies and tools. This ensures that the teaching staff is well-equipped to provide quality education.' }
    ];

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='mid-block'>
                        
                    {tabs.map((tab) => (
                        <div className='tab-view'>
                            <Tab
                                key={tab.id}
                                year={tab.year}
                                images={tab.images}
                                isActive={activeTab1 === tab.id}
                                onClick={() => setActiveTab1(tab.id)}
                            />
                            </div>
                        ))}
                        </div>
                    <div className='mid-block align-item-top'>
                    {tabs1.map((tab) => (
                        <div className='tab-view'>
                            <Tab
                                key={tab.id}
                                year={tab.year}
                                images={tab.images}
                                isActive={activeTab1 === tab.id}
                                onClick={() => setActiveTab1(tab.id)}
                            />
                            </div>
                        ))}
                       
                    </div>
                </div>

                <div className='col-lg-6 col-md-12 change-title'>
                    {tabs.map((tab) => (
                        activeTab1 === tab.id && (
                            <div key={tab.id}>
                                <h3>{tab.content}</h3>
                                <p>{tab.description}</p>
                            </div>
                        )
                    ))}
                    {tabs1.map((tab) => (
                        activeTab1 === tab.id && (
                            <div key={tab.id}>
                                <h3>{tab.content}</h3>
                                <p>{tab.description}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Facilities;
