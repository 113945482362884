import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const Facilities = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div>  
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>Facilities </li>
                        </ul>
                        <h1>  Facilities </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 faclitibox"> 
                          <h5>Library</h5> 
                          <p>The school caters to the inner quest for knowledge through a Library with literary genre namely Science, Maths, History, Geography, Literature, Fiction, Autobiography, Encyclopedia, Reference books, Journals, Magazines and News papers.</p>
                          <h5> Computer Lab </h5>
                          <p>In this modern ‘Electronic Era’ the students have ample opportunities to access to the well- equipped computer lab to catch up with the fast pace of revolution in the race of technology.</p>
                          <h5> Science Labs </h5>
                          <p>Students improve conceptual understanding and develop scientific temper through Physics, Chemistry and Biology labs, thus stimulating the young minds through various experiments.</p>
                          <h5> Arts Lab </h5> 
                          <p>Aesthetic, imaginary and creative senses are developed through Art Lab as children freely handle colours of different shades to paint or sketch.</p> 
                          <h5> Sports And Games </h5> 
                          <p>A vast playground and courts for games are provided for the children to relax physically. It makes them healthy, strong and disciplined.</p>
                    </div>
               </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Facilities