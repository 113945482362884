import React, { useState , useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Highlights from './Highlights';

const Header = () => {
    
    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };
  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
    // const MarqueeComponent = ({ children }) => {
       
      
    //     const handleMouseOver = () => {
    //       setIsHovered(true);
    //     };
      
    //     const handleMouseOut = () => {
    //       setIsHovered(false);
    //     };
      
      
    //   };

    const [isOpen, setIsOpen] = useState(window.innerWidth >= 991);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        
        <div className="header ">
            <div className="">
                <div className="container-fluid pd-0">
                    <div className="row">
                        <div className='col-md-2 highlight-tab'>
                           
                        </div>
                        <div className='col-md-7'>
                        
 

                        </div>
                        <div className='col-md-3 highlight-tab'>
                            <p className="d-flex space-even highlight-blk">
                                <a href="https://twitter.com/stthomasschool_?lang=en" target="_blank"><img src='Images/twitter.png'></img></a><img src='Images/line.png'></img>
                                <a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank"><img src='Images/insta.png'></img></a><img src='Images/line.png'></img>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src='Images/facebook.png'></img></a><img src='Images/line.png'></img>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src='Images/linkdin.png'></img></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                            <div className='logo'>
                                <Link to="/"><img src="/Images/Logo.png" className='img-fluid'></img></Link>
                            </div>
                        </div>  
                    <div className='col-lg-4 col-md-6'>
                            <div className="contact-tab"> 
                                <div><p className="border-tab"><img src="Images/phone.gif"></img> <b>Call us</b> <a href="tel: 0734-2514771">Primary : <span>0734-2514771</span></a> <a href="tel: 0734-2512771">Secondary : <span>0734-2512771</span></a></p></div> 
                                <div><p className="border-tab"><img src="Images/email.gif"></img> <b>Email us</b><a href="mailto: stmarysujn@rediffmail.com">OFFICE<br></br>  <span>stmarysujn@rediffmail.com</span></a></p></div> 
                            </div>
                        </div>
                                             
                        <div className='col-lg-4 col-md-6'>
                            <div className="header-contact"> 
                            <div><p className="border-tab">Mandatory Public Disclosure</p></div>
                                <div ><p className="border-tab">Online Fee<br></br> Payment</p></div>
                                
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="menu">
                <button className="menu-toggle" onClick={toggleMenu}>
                <i className="bi bi-list"></i>
                </button>
                {isOpen && (
                    <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">
                        <li><Link to='/'>Home</Link></li>
                        <li>about us  
                             <ul className="sub-submenu">
                                <li><Link to='/History'>History</Link></li> 
                                <li><Link to='/AimsObjectives'>Aims & Objectives</Link></li> 
                                <li><Link to="/PrincipalMessage">Principal's Message</Link></li> 
                                <li><Link to="/GeneralInformation">School Information</Link></li>
                                <li><Link to="/pdf/Booklist.pdf" target="_blank">Book List</Link></li>
 
                            </ul> 
                        </li>

                        <li><Link to="/Admission">Admission</Link></li> 
                        <li><Link to="/Facilities">Facilities</Link></li>
                        <li><Link to="/Activities">Activities</Link></li>
                         
                        <li>
                        gallery  
                          <ul className="sub-submenu">
                               
                                <li><Link to="/PhotoGallery">Photo Gallery</Link></li>
                                <li><Link to="/VideoGallery">Video Gallery </Link></li> 
                            </ul>  
                        </li> 
                        <li>
                        Alumni
                          
                        </li> 
                        <li> <Link to="/ContactUs">contact us</Link></li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Header;