import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const Admission = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div>  
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>Admission </li>
                        </ul>
                        <h1>  Admission </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" mainlist">
                    <li>  Academic year begins in April and ends in March. </li>
                    <li>  No pupils other than those seeking admission in K.G. I for the first time will be admitted without a transfer certificate from the school last attended. Students coming from other schools must get their transfer certificate duly counter-signed by the proper educational authority. </li>
                    <li>  No new admissions will be taken in class XII. Admission to all other classes is made after the student seeking admission has passed the entrance examination. Such admission is entirely dependent on the merit of the student and the vacancies available. Students who are already studying in any of the local schools will not considered for the admission to this Institution. </li>
                    <li> <b> i)</b> The admissions will entirely depend on the availability of seats. Both the parents must be present at the time of admission.  <br/>
                    <b>ii)</b> The final decision of the admission to K.G.I and other classes residessolely with the management. </li>
                    <li>  The date of birth shown on the T.C. at the time of admission will be final. Forbeginners documental proof of the date of birth must be attached to the admissionform and the documents will become the property of the school and will not bereturned. </li>
                    <li>  Admission to class XI for students coming from other schools will depend on theavailability of the seats and merit. This applies even to transfer cases. </li>
                    <li>  A pupil who has been withdrawn or has left the school due to transfers seeks readmission must pay admission fee and other fees again. </li>
                    <li>  T.C. or any other document will be issued only after all the dues have been paid off and library books and other articles have been returned to the proper personsin charge. </li>
                </ul>
                
                
                    </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Admission