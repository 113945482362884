import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const History = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div> 
       
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li> 
                            <li>About Us</li>
                            <li>History </li>
                        </ul>
                        <h1>  History </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p> <b>St. Mary’s Convent Senior Secondary School Ujjain</b> is run by the Franciscan Sisters of <b>St. Mary of the Angels (F.S.M.A)</b> a religious Congregation dates back to <b>8th July 1964</b>. Three young enthusiastic and zealous pioneers arrived at Ujjain to open an English Medium School in response to the demand of the local population and Mr. Tickoo, the then Collector of Ujjain. The School started officially functioning in <b> August 1964 </b> in a rented building at Freeganj with about <b>150 students </b> on the roll. In the year <b>1966</b>, the school was shifted to its present building.
                    This temple of learning was built brick by brick and its traditions year by year. The tiny seed, which was sown in <b>1964</b>, has grown into a mighty tree now. In the span of forty seven years, thousands of students have joyously spent their best years here. Today it has over 2000 students. </p>
                    
                    <p> <b> St. Mary’s </b> has rendered and is still rendering yeomen service in the educational field in Ujjain by endeavouring to give the best to its students in their early formative years. Realizing our social responsibility towards the marginalized section of the Society, the afternoon shift in vernacular was started in 1980, exclusively for such students. Our students have been doing wonderfully well in the Board Exams and the various competitive exams as well. We congratulate them and we are proud of them. But we don’t consider this as the only aim of a pupil’s life at school. We aim at unraveling human and spiritual excellence along with intellectual and academic excellence. </p>
                    </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default History