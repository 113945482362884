 import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      {/*  <div className="campuslogo">
         <Link to="#" target="_blank"><img src="images/Campulogo.png" className="img-fluid float-end campus" alt="St. Mary’s Convent Sr. Sec. School, Ujjain"/></Link>
  </div> */ }
      </>
        
  )
}

export default Campuscare
 