import React from 'react'
import Header from'../components/Header'
import Footer from'../components/Footer'
import { Link } from 'react-router-dom'
const Activities = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"></div>  
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ul className=" breadcrumbli">
                            <li><Link to="/">Home</Link></li>  
                            <li>Activities </li>
                        </ul>
                        <h1>  Activities </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 faclitibox"> 
                     <h4>The motto of the school <b>"Let your light shine"</b> is engraved in the life of each student of St. Mary's through various activities drawing out the hidden talents and potentialities.</h4>
                    <h5> School Assembly  </h5>
                    <p> Life in the school begins by invoking God’s blessings during the morning assembly. Each class is given the responsibility to conduct it meaningfully. Inspirational thoughts, anecdotes, proverbs, scripture readings, news and information, general knowledge etc. are parts of the assembly as per the choice of the class. On Saturdays the school song is sung with gusto. Everyday the National Anthem is sung to evoke patriotic spirit in the children. </p>
                   <h5> Universal Solidarity Movement </h5>
                    <p> The students are encouraged to join this movement. It is a movement to develop enlightened leadership, to create ecological consciousness, to promote harmony among religious and ethnic groups. </p>
                   <h5> House System </h5>
                   <p> The four Houses focus on promoting leaderships, healthy spirit of competition, growth in self confidence and team spirit. </p>
                   <h5> Yoga </h5>
                   <p> Yoga inculcates stillness of mind and concentration in the children which further motivates them to be non –aggressive and more tolerant. </p>
                   <h5> Shramdaan </h5>
                   <p> Spirit of Eco- friendliness and dignity of work are developed in the students. Maintaining the school cleanliness, planting trees are a part of this venture. </p>
                   <h5> First Aid </h5>
                    <p> Life is precious. We need to take care. The students are taught the fundamental knowledge to administer <b> “First Aid” </b> whenever it is required. </p>
                   <h5> Sports </h5>
                    <p> The students are engaged in different sports activities like football, volley ball, Leg cricket, kabaddi, dodge ball, hurdles, high jump, long jump, disc or javelin throw, races, drill, march past and many other sports activities. All these foster the values of team spirit, unity and co- operation. </p>
                   <h5> Literary and Creative Skills </h5>
                    <p> The school conducts Essay competition, dictation competition, writing competition, Quiz, Talk-show, Debates, poster competition and encourages writing articles for different magazines, thus promoting literary and creative skills. </p>
                   <h5> Scientific skills </h5>
                   <p> The students ate initiated to prepare projects, e.g. volcanic eruption, Herbarium, samples of blood group, compost and other experiments which keep them observing, exploring and performing. </p>
                   <h5> Visual and performing Arts </h5>
                   <p> The school conducts cultural activities like various dances, dramas, Mono- acts, vocal singing, display of musical talents, drawing competitions, floral and rangoli competitions, craft competitions, best out of waste, power point presentations etc. All these activities draw out the uniqueness of the individual. </p>
                   <h5> Fun-fair for charitable fund </h5>
                   <p> Every year the school organizes <b>"Fun-fair"</b> of eatable and games stalls to raise fund for the poor, which creates in them sensitivity towards the needy brothers and sisters. </p>
                   <h5> School Diary </h5>
                   <p> The School diary is to be brought to class daily without fail. Parents and guardians should watch for any remarks or notification made by the school in the diary. </p>
                    </div>
               </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default Activities